<template>
    <div class="add_audit">
        <div v-if="(assess==''||assess==null||assess==undefined)&&!pinggu_project_power.power.edit">-</div>
        <el-button size="small" type="primary" @click="show=true" class="button_78px" v-if="(assess==''||assess==null||assess==undefined)&&pinggu_project_power.power.edit">添加评估</el-button>
        <el-button size="small" type="warning" @click="open" class="button_78px" v-if="assess!=''&&assess!=null&&assess!=undefined">查看评估</el-button>
        <el-dialog :title="assess?'查看评估':'添加评估'" :visible.sync="show" width="500px" @closed="close" append-to-body>
            <div class="content">
                <div class="title">项目评估</div>
                <el-input type="textarea" :rows="4" placeholder="请填写项目评估" v-model="value"></el-input>
            </div>
            <div class="button">
                <el-button class="button_88px" size="small" type="primary" @click="addAuditCheckAssess" v-if="pinggu_project_power.power.edit">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import computedData from '@/mixins/computedData';
    import api from '@/api/methods/index';
    export default {
        mixins:[computedData],
        props:['project_id','assess'],
        data(){
            return{
                type:1,
                show:false,
                value:''
            }
        },
        computed: {
            pinggu_project_power() {
                return this.$store.state.user.current_user.pinggu_project_power
            }
        },
        methods:{
            open(){
                this.show=true;
                this.value=this.assess;
            },
            addAuditCheckAssess(){
                let param={project_id:this.project_id,type:3,value:this.value};
                api.project.addAuditCheckAssess(param).then(res=>{
                    if(res.data.state==1){
                        this.$message({
                            message: `项目评估保存成功`,
                            type: 'success'
                        });
                        this.$emit('refresh');
                        this.show=false;
                    }
                })
            },
            close(){
                this.show=false;
                this.value='';
            }
        }
    }
</script>

<style scoped lang="less">
    /deep/ div.el-dialog{
        .content{
            padding:10px 20px;
            .title{
                padding:10px 0;
            }
            .el-textarea .el-textarea__inner{
                resize: none;
            }
        }
        .button{
            border:none!important;
        }
    }
</style>