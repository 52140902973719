<template>
    <div class="main project">
        <ul class="tabs">
            <li>项目列表</li>
        </ul>
        <div class="container">
            <div class="filter">
                <el-date-picker size="medium" v-model="year" type="year" value-format="yyyy" placeholder="请选择项目年度"
                                @change="search" style="margin-right:10px;width: 200px;"></el-date-picker>
                <el-select size="medium" v-model="area" popper-class="global_select_popper" @change="search"
                           style="margin-right:10px;width: 200px;" :disabled="pinggu_project_power.area>0">
                    <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                               :value="item.value"></el-option>
                </el-select>
                <!--<el-button size="small" type="primary" plain class="button_68px" @click="search">查询</el-button>-->
            </div>
            <div class="table">
                <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                    <el-table-column label="序号" align="center">
                        <template slot-scope="scope">
                            <span :style="{color:apiReturnColor(scope.row.color)}">{{scope.row.project_number}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="project_name" label="项目名称" align="center"></el-table-column>
                    <!--<el-table-column prop="project_fuze" label="项目负责人" align="center"></el-table-column>-->
                    <el-table-column label="项目类别" align="center">
                        <template slot-scope="scope">
                            <span>{{initProjectGenre(scope.row.project_genre)}}</span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column label="项目性质" align="center">
                        <template slot-scope="scope">
                            <span>{{initProjectQuality(scope.row.project_quality)}}</span>
                        </template>
                    </el-table-column>-->
                    <el-table-column label="是否已评估" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.assess!==''&&scope.row.assess!=null&&scope.row.assess!==undefined"
                                  style="color:#0db829;">是</span>
                            <span v-else style="color:#ff6a6a;">否</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总投资金额(万元)" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.all_invest_num">{{Number(scope.row.all_invest_num)/10000}}万元</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目进度" align="center" width="195">
                        <template slot-scope="scope">
                            <div class="progress">
                                <el-progress :stroke-width="12"
                                             :percentage="isNaN(Number(scope.row.year_project_evolve_rate))?0:Number(scope.row.year_project_evolve_rate)"
                                             :color="apiReturnColor(scope.row.color)"></el-progress>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <addAssess :project_id="scope.row.project_id" :assess="scope.row.assess"
                                       @refresh="projectList"></addAssess>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                                   layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import computedData from '@/mixins/computedData.js';
    import addAssess from '@c/assess/add_assess.vue';
    import customColor from '@/mixins/customColor.js'
    import globalYear from '@/mixins/global_year'

    export default {
        components: {addAssess},
        mixins: [globalYear,computedData, customColor],
        data() {
            return {
                // year: new Date().getFullYear().toString(),
                areaList: [
                    {value: 0, label: '全部(默认)'},
                    {value:1, label:'州本级'},
                    {value:2, label:'玛沁县'},
                    {value:5, label:'甘德县'},
                    {value:6, label:'达日县'},
                    {value:4, label:'班玛县'},
                    {value:3, label:'久治县'},
                    {value:7, label:'玛多县'},
                ],
                area: 0,
                tableData: [],
                page: 1, pagesize: 15, total: 0,
                loading: false,
            }
        },
        computed: {
            pinggu_project_power() {
                return this.$store.state.user.current_user.pinggu_project_power
            },
            current_userid() {
                return this.$store.state.user.current_user.userid
            }
        },
        created() {
            if (this.pinggu_project_power.area>0) {
                this.area = this.pinggu_project_power.area;
            }
            this.projectList()
        },
        methods: {
            projectList() {
                this.loading = true;
                this.tableData = [];
                let param = {page: this.page, pagesize: this.pagesize};
                if (this.year) param.year = this.year;
                if (this.area) param.area = this.area;
                if (!this.pinggu_project_power.area) {
                    param.fuze_users = this.current_userid;
                }
                // if (this.pinggu_project_power.area === 1) {
                //     param.fuze_users = this.current_userid;
                // }
                api.project.projectList(param).then(res => {
                    if (res.data) {
                        this.total = res.data.count || 0;
                        this.tableData = res.data.lists || [];
                    }
                    this.loading = false;
                })
            },
            search() {
                this.page = 1;
                this.projectList();
            },
            pageChange(page) {
                this.page = page;
                this.projectList();
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../project/project.less";
</style>